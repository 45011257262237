import React from "react";
import Layout from "../../components/Layout/Layout";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import LegalNav from "../../components/LegalNav/LegalNav";

const Content = () => {
  return (
    <>
      <p>
        These terms tell you about the rules for using our website:
        tcardsonline.com/.co.uk (our site).
      </p>
      <ul>
        <li>Who we are and how to contact us.</li>
        <li>By using our site, you accept these terms.</li>
        <li>There are other terms that may apply to you.</li>
        <li>We may make changes to these terms.</li>
        <li>We may make changes to our site.</li>
        <li>We may suspend of withdraw our site.</li>
        <li>We may transfer this agreement to someone else.</li>
        <li>You must keep your account details safe.</li>
        <li>How you may use material on our site.</li>
        <li>Do not rely on information on our site.</li>
        <li>User-generated content is not approved by us.</li>
        <li>How to complain about content uploaded by another user.</li>
        <li>Our responsibility for loss or damage suffered by you.</li>
        <li>Exclusion of liability for digital content.</li>
        <li>How we may use your personal information.</li>
        <li>Uploading content to our site.</li>
        <li>Rights you are giving us to use material you upload.</li>
        <li>
          We are not responsible for viruses and you must not introduce them.
        </li>
        <li>Rules about linking to our site.</li>
        <li>Which country's laws apply to any disputes?</li>
        <li>Our trading name as registered.</li>
      </ul>
      <p>
        <b>Who we are and how to contact us?</b>
      </p>
      <p>
        tcardsonline is a site operated by T Cards Direct. a Partnership ("we”
        “us”). Our registered address is Lillian House, Clearway, Addington,
        Kent, ME19 5BP United Kingdom.
        <br />
        To contact us, please email{" "}
        <a href='mailto:support@tcardsonline.com'>
          support@tcardsonline.com
        </a>{" "}
        or telephone us on 00 44 (0)1732 871417.
      </p>
      <p>
        <b>By using our site, you accept these terms</b>
      </p>
      <p>
        By using our site, you confirm that you accept these terms of use and
        that you agree to comply with them.
      </p>
      <p>If you do not agree to these terms, you must not use our site.</p>
      <p>
        We recommend that you print a copy of these terms for future reference.
      </p>
      <p>
        <b>There are other terms that apply to you</b>
      </p>
      <p>
        These terms of use refer to the following additional terms, which also
        apply to your use of our site:
      </p>
      <ul>
        <li>
          Our <a href='/legal/privacy'>Privacy Policy.</a> See further under How
          we may use your personal information
        </li>
        <li>
          Our <a href='/legal/acceptable-use'> Acceptable Use Policy.</a> Which
          sets out the permitted uses and prohibited uses of our site. When
          using our site, you must comply with this Acceptable Use Policy.
        </li>
        <li>
          Our <a href='/legal/cookies'>Cookie Policy</a>
          Which sets out information about the cookies on our site.
        </li>
      </ul>

      <p>
        <b>We may make changes to these terms</b>
      </p>
      <p>
        We amend these terms from time to time. Every time you wish to use our
        site, please check these terms to ensure you understand the terms that
        apply at that time.
        <br />
        These terms were most recently updated in August 2021.
      </p>
      <p>
        <b>We may make changes to our site</b>
      </p>
      <p>
        We may update and change our site from time to time to reflect changes
        to our services, our users' needs and our business priorities.
      </p>
      <p>
        <b>We may suspend or withdraw our site</b>
      </p>
      <p>
        Visits to our site made available free of charge. However, should you
        decide to register to use and subscribe to our tcardsonline Service then
        you agree to pay such User Subscriptions in order to access and use the
        Services and Documentation.
      </p>
      <p>
        We do not guarantee that our site, or any content on it, will always be
        available or be uninterrupted. We may suspend or withdraw or restrict
        the availability of all or any part of our site for business and
        operational reasons. We will try to give you reasonable notice of any
        suspension or withdrawal.
      </p>
      <p>
        You are also responsible for ensuring that all persons who access our
        site through your internet connection are aware of these terms of use
        and other applicable terms and conditions , and that they comply with
        them.
      </p>
      <p>
        <b>We may transfer this agreement to someone else</b>
      </p>
      <p>
        We may transfer our rights and obligations under these terms to another
        organisation. We will always tell you in writing if this happens and we
        will ensure that the transfer will not affect your rights under the
        contract.
      </p>
      <p>
        <b>You must keep your account details safe</b>
      </p>
      <p>
        If you choose, or you are provided with, a user identification code,
        password or any other piece of information as part of our security
        procedures, you must treat such information as confidential. You must
        not disclose it to any third party.
      </p>
      <p>
        We have the right to disable any user identification code or password,
        whether chosen by you or allocated by us, at any time, if in our
        reasonable opinion you have failed to comply with any of the provisions
        of these terms of use.
      </p>
      <p>
        If you know or suspect that anyone other than you knows your user
        identification code or password, you must promptly notify us at by email
        at{" "}
        <a href='mailto:support@tcardsonline.co.uk'>
          support@tcardsonline.co.uk
        </a>
      </p>
      <p>
        <b>How you may use material on our site</b>
      </p>
      <p>
        We are the owner or the licensee of all intellectual property rights in
        our site, and in the material published on it. Those works are protected
        by copyright laws and treaties around the world. All such rights are
        reserved.
      </p>
      <p>
        You may print off one copy, and may download extracts, of any page(s)
        from our site for your personal use and you may draw the attention of
        others within your organisation to content posted on our site.
      </p>
      <p>
        You must not modify the paper or digital copies of any materials you
        have printed off or downloaded in any way, and you must not use any
        illustrations, photographs, video or audio sequences or any graphics
        separately from any accompanying text.
      </p>
      <p>
        Our status (and that of any identified contributors) as the authors of
        content on our site must always be acknowledged.
      </p>
      <p>
        You must not use any part of the content on our site for commercial
        purposes without obtaining a licence to do so from us or our licensors.
      </p>
      <p>
        If you print off, copy or download any part of our site in breach of
        these terms of use, your right to use our site will cease immediately
        and you must, at our option, return or destroy any copies of the
        materials you have made.
      </p>
      <p>
        <b>Do not rely on information on this site</b>
      </p>
      <p>
        The content on our site is provided for general information only. It is
        not intended to amount to advice on which you should rely. You must
        obtain professional or specialist advice before taking, or refraining
        from, any action on the basis of the content on our site.
      </p>
      <p>
        Although we make reasonable efforts to update the information on our
        site, we make no representations, warranties or guarantees, whether
        express or implied, that the content on our site is accurate, complete
        or up to date.
      </p>
      <p>
        <b>We are not responsible for the websites we link to</b>
      </p>
      <p>
        Where our site contains links to other sites and resources provided by
        third parties, these links are provided for your information only. Such
        links should not be interpreted as approval by us of those linked
        websites or information you may obtain from them.
        <br />
        We have no control over the contents of those sites or resources.
      </p>
      <p>
        <b>User-generated content is not approved by us</b>
      </p>
      <p>
        This website may include information and materials uploaded by other
        users of the site, including to bulletin boards and chat rooms. This
        information and these materials have not been verified or approved by
        us. The views expressed by other users on our site do not represent our
        views or values.
      </p>
      <p>
        <b>How to complain about content uploaded by other users</b>
      </p>
      <p>
        If you wish to complain about content uploaded by other users, please
        contact us by emailing{" "}
        <a href='mailto:support@tcardsonline.com'>support@tcardsonline.com</a>.
      </p>
      <p>
        <b>Our responsibility for loss or damage suffered by you</b>
      </p>

      <ul>
        <li>
          We do not exclude or limit in any way our liability to you where it
          would be unlawful to do so. This includes liability for death or
          personal injury caused by our negligence or the negligence of our
          employees, agents or subcontractors and for fraud or fraudulent
          misrepresentation.
        </li>
        <li>
          Different limitations and exclusions of liability will apply to
          liability arising as a result of the supply of any services to you,
          which will be set out in our to tcardsonline Service Terms and
          Conditions.
        </li>
        <li>
          We exclude all implied conditions, warranties, representations or
          other terms that may apply to our site or any content on it.
        </li>
        <li>
          • We will not be liable to you for any loss or damage, whether in
          contract, tort (including negligence), breach of statutory duty, or
          otherwise, even if foreseeable, arising under or in connection with:
          <ul>
            <li>use of, or inability to use, our site; or</li>
            <li>use of or reliance on any content displayed on our site .</li>
          </ul>
        </li>
        <li>
          In particular, we will not be liable for:
          <ul>
            <li>loss of profits, sales, business, or revenue;</li>
            <li>business interruption;</li>
            <li>loss of anticipated savings;</li>
            <li>loss of business opportunity, goodwill or reputation; or</li>
            <li>any indirect or consequential loss or damage.</li>
          </ul>
        </li>
      </ul>
      <p>
        <b>How we may use your personal information</b>
      </p>
      <p>
        We will only use your personal information as set out in our{" "}
        <a href='/legal/privacy'>Privacy Policy</a>.
      </p>
      <p>
        <b>
          We are not responsible for viruses and you must not introduce them
        </b>
      </p>
      <p>
        We do not guarantee that our site will be secure or free from bugs or
        viruses.
      </p>
      <p>
        You are responsible for configuring your information technology,
        computer programmes and platform to access our site. You should use your
        own virus protection software.
      </p>
      <p>
        You must not misuse our site by knowingly introducing viruses, trojans,
        worms, logic bombs or other material that is malicious or
        technologically harmful. You must not attempt to gain unauthorised
        access to our site, the server on which our site is stored or any
        server, computer or database connected to our site. You must not attack
        our site via a denial-of-service attack or a distributed denial-of
        service attack. By breaching this provision, you would commit a criminal
        offence under the Computer Misuse Act 1990. We will report any such
        breach to the relevant law enforcement authorities and we will
        co-operate with those authorities by disclosing your identity to them.
        In the event of such a breach, your right to use our site will cease
        immediately.
      </p>
      <p>
        <b>Rules about linking to our site</b>
      </p>
      <p>
        You may link to our home page, provided you do so in a way that is fair
        and legal and does not damage our reputation or take advantage of it.
      </p>
      <p>
        You must not establish a link in such a way as to suggest any form of
        association, approval or endorsement on our part where none exists.
      </p>
      <p>
        You must not establish a link to our site in any website that is not
        owned by you.
      </p>
      <p>
        Our site must not be framed on any other site, nor may you create a link
        to any part of our site other than the home page.
      </p>
      <p>We reserve the right to withdraw linking permission without notice.</p>
      <p>
        The website in which you are linking must comply in all respects with
        the content standards set out in our{" "}
        <a href='/legal/acceptable-use'> Acceptable Use Policy.</a>
      </p>
      <p>
        If you wish to link to or make any use of content on our site other than
        that set out above, please contact{" "}
        <a href='mailto:support@tcardsonline.com'>support@tcardsonline.com</a>
      </p>
      <p>
        <b>Which country's laws apply to any disputes?</b>
      </p>
      <p>
        These terms of use, their subject matter and their formation (and any
        non-contractual disputes or claims) are governed by English law. We both
        agree to the exclusive jurisdiction of the courts of England and Wales.
      </p>
      <p>
        <b>Our trademarks are registered</b>
      </p>
      <p>
        “tcards online.” is a trademark of ours. You are not permitted to use it
        without our approval, unless it is part of material you are using as
        permitted under How you may use material on our site.
      </p>
    </>
  );
};

const TermsConditionsPage = () => {
  return (
    <Layout>
      <SidebarLayout
        title='Terms & Conditions'
        nav={<LegalNav />}
        content={<Content />}
      />
    </Layout>
  );
};

export default TermsConditionsPage;
